import React from 'react';
import { photoSrc } from "../../../../util/media";
import {date, dateTime, price} from "../../../../util/formatting";

import IconLabel from "../../../atoms/icon-label";
import Label from "../../../atoms/label";
import IconButton from "../../../atoms/icon-button";
import StatusLabel from "../../../atoms/status-label";
import Dropout from "../../dropout";
import Checkbox from "../../../atoms/checkbox";
import InputStack from "../../input-stack";
import {API_HOST, API_ROOT, http} from "../../../../util/http";
import {handleTokenErrors} from "../../../../state/modules/errors";
import TextInput from "../../../atoms/text-input";
import Form from "../../form";
import ui from "../../../../util/ui";

class PickListItem extends React.Component {
    static defaultProps = {
        model: {},
        onViewList: () => {}
    };
    
    constructor(props) {
        super(props);
        
        this.currentQuantity = React.createRef();
        this.requestedQuantity = React.createRef();
        this.fulfilledQuantity = React.createRef();
        
        this.state = {
            model: props.model
        };
    }
    
    render() {
        const { model } = this.state;
        
        let formats;

        if (model.release.formats_string) {
            formats = <StatusLabel modifiers={['error', 'compact']} title={model.release.formats_string}/>;
        }
        
        return (
            <div className="list-item">
                <div
                    className="list-item__column list-item__column--valign-top list-item__column--clean list-item__column--span-3">
                    {model.release.title} {formats}<br/>
                    {model.release.artist.name}<br/>
                    {price(model.release.price)}
                </div>
                <div className="list-item__column list-item__column--valign-top list-item__column--span-3">
                    Barcode: {model.release.barcode}<br/>
                    {model.release.label && (
                        <div>
                            <IconLabel text={model.release.label} iconModifiers={'small'} type="vinyl"/><br/>
                        </div>
                    )}
                    {model.release.release_date > 0 && (
                        <IconLabel text={date(model.release.release_date)} type={'calendar'} iconModifiers={'small'}/>
                    )}
                </div>
                <div className="list-item__column list-item__column--valign-top list-item__column--span-3">
                    <TextInput ref={this.currentQuantity}
                               name="current_quantity"
                               type="number"
                               label="current quantity"
                               default={model.frozen_quantity}
                               disabled={true}
                    />
                </div>
                <div className="list-item__column list-item__column--valign-top list-item__column--span-3">
                    <TextInput ref={this.requestedQuantity}
                               name="requested_quantity"
                               type="number"
                               label="requested quantity"
                               default={model.requested_quantity}
                               disabled={true}/>
                </div>
                <div className="list-item__column list-item__column--valign-top list-item__column--span-3">
                    <TextInput ref={this.fulfilledQuantity}
                               name="fulfilled_quantity"
                               type="number"
                               step="1"
                               label="fulfilled quantity"
                               default={model.requested_quantity}
                               disabled={model.status === 'completed'}
                    />
                </div>
                <div className="list-item__column list-item__column--valign-top">
                    <Checkbox name="pick" label="Pick" default={model.status === 'completed'} onChange={(checked) => {
                        const action = checked ? 'pick' : 'unpick';
                        
                        const data = checked ? {
                            fulfilled_quantity: this.fulfilledQuantity.current.state.value
                        } : {};
                        
                        http
                            .post(API_HOST + API_ROOT + 'pick_lists_items/' + model.id + '/' + action, data, true)
                            .then(response => {
                                if (response.success) {
                                    if (action === 'unpick') {
                                        this.fulfilledQuantity.current.state.value = response.result.requested_quantity;
                                    }
                                    
                                    this.setState({
                                        model: response.result
                                    });
                                    
                                    ui.notify('Pick list item ' + action + 'ed');
                                }
                            })
                            .catch(error => {
                                ui.notify(error, 'error');
                            });
                    }}/>
                </div>
            </div>
        );
    }
}

export default PickListItem;