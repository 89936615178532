export const orderLabelModifier = (status) => {

  let orderStatusMap = {
    'Open': 'warning',
    'Paid': 'success',
    'Cancelled': '',
    'Failed': 'error',
    'Expired': '',
    'Refunded': 'info'
  };

  return orderStatusMap[status] || '';
};

export const paymentMethodLabelModifier = (paymentMethod) => {

  let paymentMethodMap = {
    'cash': 'cash',
    'card': 'card',
    'mollie': 'card'
  };

  return paymentMethodMap[paymentMethod] || '';
};