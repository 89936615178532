import { API_HOST, API_ROOT, http } from "../../../../util/http";

import React from 'react';
import Grid from "../../grid";
import Window from "../../window";
import {validateForm} from "../../../../util/validation";
import Form from "../../form";
import TextInput from "../../../atoms/text-input";
import InputStack from "../../input-stack";
import ui from "../../../../util/ui";
import HiddenInput from "../../../atoms/hidden-input";
import {price} from "../../../../util/formatting";
import login from "../../../organisms/login";

class RegisterClose extends React.Component
{
    static defaultProps = {
        register: {},
        onSave: () => {},
    };
    
    constructor(props) {
        super(props);
        
        let methods = {};
        
        Object.keys(this.props.register.collected).forEach((method) => {
            methods[method] = this.props.register.collected[method];
        });
        
        this.state = {
            register: this.props.register,
            form: methods,
        };
        
        this.registerEditForm = React.createRef();
    }
    
    validateForm(form, data) {
        let validationRules = {};
        
        Object.keys(this.state.register.collected).forEach((method) => {
            validationRules[method] = ['required', 'number'];
        });
        
        validateForm(form, data, validationRules);
    }
    
    handleChange(fieldName, value) {
        this.setState({
            form: {
                ...this.state.form,
                [fieldName]: value
            }
        });
    }
    
    onSubmit(registerData) {
        http
            .patch(API_HOST+API_ROOT + 'registers/' + this.state.register.id, registerData, true)
            .then(response => {
                if (response.success) {
                    this.props.onSave(this.state.register);
                }
            })
            .catch(error => {
                console.log(error)
                ui.notify('There was an error, register couldn\'t be closed', 'error');
            });
    }
    
    render() {
        let register = this.state.register;
        
        let form = this.state.form;
        
        if (! register) {
            return;
        }
        
        return (
            <Grid rows={'1fr auto'}>
                <Window>
                    <Form ref={this.registerEditForm}
                          validate={(form, data) => this.validateForm(form, data)}
                          onSubmit={(data) => this.onSubmit(data)}
                          submitButtonText={'Close register'}
                    >
                        <HiddenInput name="closed" value={new Date().getTime() / 1000} />
                        {Object.keys(form).length && (
                            <InputStack>
                                {Object.keys(form).map(method => {
                                    if (method === 'mollie') {
                                        return (
                                            <TextInput key={method} name={method} label={method + " counted"} showRequiredIndicator={true} default={parseFloat(form[method]).toFixed(2)} disabled />
                                        );
                                    }
                                    else if (method === 'discount') {
                                        return (
                                            <TextInput key={method} name={method} label={method + " counted"} showRequiredIndicator={true} default={parseFloat(form[method]).toFixed(2)} disabled />
                                        );
                                    }
                                    
                                    return (
                                        <TextInput key={method} name={method} label={method + " counted"} showRequiredIndicator={true} placeholder={parseFloat(form[method]).toFixed(2)} onChange={value => this.handleChange(method, value)} />
                                    );
                                })}
                            </InputStack>
                        )}
                        <TextInput name={'note'} label={'Notes'} default={register.note} />
                    </Form>
                </Window>
            </Grid>
        );
    }
}

export default RegisterClose;