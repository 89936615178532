import React from 'react';
import { price } from "../../../../util/formatting";
import { paymentMethodLabelModifier } from "../../../../util/label-colors";

import StatusLabel from "../../../atoms/status-label/index";

class StoreOrderListItem extends React.Component {

  static defaultProps = {
    model: {},
  };

  getPaymentMethodLabel() {
    let paymentMethodLabel = (
        <StatusLabel title={'Unknown'} />
    );
    
    if (this.props.model.payment_method) {
      paymentMethodLabel = (
          <StatusLabel title={this.props.model.payment_method} modifiers={paymentMethodLabelModifier(this.props.model.payment_method)} />
      );
    }
    
    if (this.props.model.payment_methods) {
      paymentMethodLabel = this.props.model.payment_methods.map((method) => (
          <StatusLabel key={method} title={method} modifiers={paymentMethodLabelModifier(method)} />
      ));
    }
    
    return paymentMethodLabel;
  }

  render() {

    let name = '-';

    if (this.props.model.customer) {
      name = this.props.model.customer.first_name+ ' ' +this.props.model.customer.last_name;
    }

    let items = [];

    if (this.props.model.order_releases) {
      this.props.model.order_releases.forEach((orderRelease, key) => {
        items.push(<li key={key+'-release'}>{orderRelease.release.subtitle} - {orderRelease.release.title}</li>);
      });
    }

    if (this.props.model.giftcards) {
      this.props.model.giftcards.forEach((giftcard, key) => {
        items.push(<li key={key+'-giftcard'}>{giftcard.title} - {giftcard.subtitle}</li>);
      });
    }

    if (this.props.model.custom_products) {
      this.props.model.custom_products.forEach((customProduct, key) => {
        items.push(<li key={key+'-customProduct'}>{customProduct.title} - {customProduct.subtitle}</li>);
      });
    }

    if (this.props.model.order_client_orders) {
      this.props.model.order_client_orders.forEach((orderClientOrder, key) => {
        items.push(<li key={key+'-clientOrder'}>{orderClientOrder.client_order.title} - {orderClientOrder.client_order.subtitle}</li>);
      });
    }

    return (
      <React.Fragment>
        <div className="list-item">
          <span className="list-item__column list-item__column--clean list-item__column--span-2">
            <span className="list-item__title">{this.props.model.order_id}</span>
            {price(this.props.model.total_price)} {this.props.model.formatted_discount && <StatusLabel modifiers={'varia-3'} title={this.props.model.formatted_discount}/>}<br />
            {name}
          </span>
          <span className="list-item__column list-item__column--span-5">
            <ul className="list-item__column-list">
              {items}
            </ul>
          </span>
          <span className="list-item__column">
            {this.getPaymentMethodLabel()}
          </span>
        </div>
      </React.Fragment>
    );
  }
}

export default StoreOrderListItem;