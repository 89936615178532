import React from 'react';
import Window from "../../window";
import Pagination from "../../pagination";
import List from "../../list";
import PickListRow from "../pick-list-row";
import HeaderColumn from "../../../atoms/header-column";
import Header from "../../header";

class PickLists extends React.Component
{
    static defaultProps = {
        onViewList: () => {}
    };
    
    constructor(props) {
        super(props);
        
        this.state = {};
    }
    
    componentDidMount() {
        
    }
    
    render() {
        return (
            <Window modifiers="depth" footer={<Pagination listId="pick_lists"/>}>
                <List
                    id={'pick_lists'}
                    model={'pick_lists'}
                    selectable={false}
                    header={(
                        <Header listId={'pick_lists'}>
                            <HeaderColumn title="Id" field={'sort_id'} defaultSorting={'desc'} />
                            <HeaderColumn title="Location" field={'sort_location'} modifiers={'span-4'} />
                            <HeaderColumn title="Date" field={'sort_created'} />
                            <HeaderColumn title="Status" field={'sort_status'} />
                            <HeaderColumn />
                        </Header>
                    )}
                    onItemClick={(list) => this.props.onViewList(list)}
                >
                    <PickListRow onViewList={(list) => this.props.onViewList(list)} />
                </List>
            </Window>
        );
    }
}

export default PickLists;