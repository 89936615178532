import connect from "react-redux/es/connect/connect";
import {setList, updateList} from "../../../../state/modules/resources";
import {
    addClientOrder,
    addCustomProduct,
    addDiscount,
    addGiftcard,
    addProductVariation,
    addRelease,
    checkoutCart,
    checkoutMollieCart,
    deleteCart,
    fetchCart,
    fetchCarts,
    removeCartItem,
    removeDiscount,
    updateCart, updateCartItem,
    updateProductVariation,
    updateRelease,
} from "../../../../state/modules/carts";
import {API_HOST, API_ROOT, http} from "../../../../util/http";
import {handleTokenErrors} from "../../../../state/modules/errors";

import React from "react";
import {price} from "../../../../util/formatting";
import ui from "../../../../util/ui";

import Button from "../../../atoms/button";
import Popup from "../../popup";
import ModuleView from "../../module-view/index";
import CustomerSelectListItem from "../../customer/customer-select-list-item";
import CustomerCreate from "../../customer/customer-create";
import ItemSelectPopup from "../../item-select-popup";
import Window from "../../window";
import Grid from "../../grid";
import CustomerCard from "../../customer/customer-card";
import ButtonGroup from "../../button-group";
import List from "../../list";
import NumberWidget from "../../number-widget";
import CartItem from "../cart-item";
import BarcodeScanner from "../../barcode-scanner";
import CustomProductTypeListItem from "../../custom-product-type/custom-product-type-list-item";
import CustomProductCreate from "../../custom-product/custom-product-create";
import ActionInput from "../../action-input";
import DataValue from "../../data-value";
import IconButton from "../../../atoms/icon-button";
import StatusLabel from "../../../atoms/status-label";
import OrderView from "../../order/order-view";
import GiftcardCreate from "../../giftcard/giftcard-create";
import ReleaseCompactItem from "../../inventory/release-compact-list-item";
import CartIndex from "../cart-index";
import RegisterCreate from "../../register/register-create";
import RegisterEdit from "../../register/register-edit";
import RegisterTransactionCreate from "../../register/register-transaction-create";
import Dropout from "../../dropout";
import ClientOrderCreate from "../../client-order/client-order-create";
import ClientOrderListItem from "../../client-order/client-order-list-item";
import ProductVariationCompactItem from "../../inventory/product-variation-compact-list-item";
import Discount from "../../discount";
import time from "../../../../util/time";
import CartListItem from "../cart-list-item";
import OrderListItem from "../../order/order-list-item";
import Search from "../../search";
import Checkbox from "../../../atoms/checkbox";
import InputStack from "../../input-stack";
import Loader from "../../../atoms/loader";
import {resolve} from "path";
import ActionButton from "../../../atoms/action-button";
import TextInput from "../../../atoms/text-input";
import modifiers from "../../../../util/components";
import {ca} from "wait-on/exampleConfig";
import CartCheckoutScreen from "../cart-checkout-view";
import HiddenInput from "../../../atoms/hidden-input";
import Form from "../../form";
import RegisterClose from "../../register/register-close";

class CartModule extends ModuleView {

    constructor(props) {

        super(props);
        
        this.customProductCreate = React.createRef();
        this.giftcardCreate = React.createRef();

        this.views = {
            index: <CartIndex
                onCartClick={(cart, register) => this.openPopup('cartView', {cart: cart, register: register}, cart.id)}
                onOrderClick={order => this.openPopup('viewOrder', order, order.id)}
                onRegisterOpenClick={() => this.openPopup('createRegister', null, 'create-register')}
                onManageRegisterClick={register => this.openPopup('manageRegister', register, 'manage-register')}
                onCheckoutOrderClick={order => this.openPopup('checkoutOrder')}
            />
        };
        
        this.popups = {
            cartView: (props) => {
                let cart = (this.props.carts[props.cart.id] ? this.props.carts[props.cart.id] : props.cart);

                let customerCard = (
                    <div>No customer selected</div>
                );

                if (cart.customer) {
                    customerCard = (
                        <div>
                            <CustomerCard customer={cart.customer}/>
                            {cart.customer.company_vat_number &&
                                <InputStack>
                                    <Checkbox modifiers={'padding-top'} name="create_invoice" label="Create invoice" default={this.state.createInvoice} onChange={(checked) => {
                                        this.setState({
                                            createInvoice: checked,
                                        });
                                    }}/>
                                </InputStack>
                            }
                        </div>
                    );
                }
                
                let footer = (
                    <Button text={'Create order'} onClick={e => {
                        this.closePopup('cartView', cart.id, () => {
                            // Add register id to request
                            http.post(API_HOST + API_ROOT + 'carts/' + cart.id + '/create-order', {
                                    register: cart.register.id
                                }, true)
                                .then(response => {
                                    this.openPopup('cartCheckoutView', {
                                        cart: cart,
                                        order: response.order
                                    }, cart.id);
                                })
                        });
                    }}/>
                );

                let customerWindowHeader = (
                    <ButtonGroup>
                        <Button text="Create" modifiers="alt"
                                onClick={() => this.openPopup('customerCreate', cart, cart.id)}/>
                        <Button text={cart.customer ? 'Change' : 'Search'}
                                onClick={e => this.openPopup('customerSelect', cart, cart.id)}/>
                    </ButtonGroup>
                );

                let discountCode = (
                    <div>
                        {cart.discounts && cart.discounts.map(discount => {
                            return (
                                <Discount key={discount.discount_code.code} title={discount.discount_code.code} modifiers={'compact'}>
                                    <StatusLabel modifiers={'success'} title={'- ' + price(discount.value)}/>
                                    <IconButton iconType="delete" onClick={e => {
                                        this.props.removeDiscount(cart.id, discount.id).then(() => {
                                            ui.notify('Discount removed');

                                            this.props.updateList('carts');

                                            this.openPopup('cartView', {cart: cart}, cart.id);
                                        });
                                    }} />
                                </Discount>
                            );
                        })}
                        <ActionInput
                            buttonText={'Redeem'}
                            onSubmit={(code, field) => {
                                http.get(API_HOST + API_ROOT + 'discounts/findbycode', true, {code: code})
                                    .then(response => {
                                        this.props.addDiscount(cart.id, response.result.id)
                                            .then((response) => {
                                                if (response === undefined) {
                                                    ui.notify('Discount code already in cart', 'error');
                                                    return;
                                                }

                                                ui.notify('Discount added');

                                                this.props.updateList('carts');

                                                this.openPopup('cartView', {cart: cart}, cart.id);

                                                field.updateValue('');
                                            })
                                            .catch(error => handleTokenErrors(error));
                                    })
                                    .catch(error => {
                                        if (cart.discounts) {
                                            this.props.removeDiscount(cart.id).then(() => {
                                                this.props.updateList('carts');

                                                this.openPopup('cartView', {cart: cart}, cart.id);
                                            });
                                        }

                                        if (error.message === 'discount_code_not_found') {
                                            ui.notify('Discount code not found', 'error');
                                        }

                                        if (error.message === 'discount_code_usage_limit_exceeded') {
                                            ui.notify('Discount code usage limit exceeded', 'error');
                                        }
                                    })
                                    .catch(error => handleTokenErrors(error));
                            }}/>
                    </div>
                );
                
                let plasticBag = null;
                
                if (this.state.plasticBagCustomProductType) {
                    plasticBag = (
                        <Button modifiers={['small']} text="plastic bag"
                                onClick={() => {
                                    this.props.addCustomProduct(cart.id, this.state.plasticBagCustomProductType.id, {amount: 1}).then(() => {
                                        ui.notify('Plastic bag added to cart');
            
                                        this.openPopup('cartView', {cart: cart}, cart.id);
            
                                        this.props.updateList('carts');
                                        this.props.updateList('cart-items-' + cart.id);
                                    });
                                }}/>
                    );
                }
                
                let ticket = null;
                
                if (this.state.ticketProductType) {
                    ticket = (
                        <Button modifiers={['small']} text="ticket"
                                onClick={() => {
                                    this.openPopup('customProductAdd', {
                                        customProductType: this.state.ticketProductType,
                                        cart: cart
                                    }, cart.id);
                                }}/>
                    );
                }

                return (
                    <Popup title={'Cart #' + cart.id} fullscreen={true} footer={footer}>
                        <Grid cols={'5fr 3fr'}>
                            <Window title={cart.items ? "Products (" + cart.items.length + ")" : "Products"} modifiers={['frame', 'non-padded']} header={
                                <ButtonGroup>
                                    <Button modifiers={'small'} text="release"
                                            onClick={() => this.openPopup('releaseSelect', cart, cart.id)}/>
                                    <Button modifiers={'small'} text="2nd hand"
                                            onClick={() => {
                                                if (this.state.secondHandCustomProductType) {
                                                    this.openPopup('customProductAdd', {
                                                        customProductType: this.state.secondHandCustomProductType,
                                                        cart: cart
                                                    }, cart.id);
                                                }
                                            }}/>

                                    <Button modifiers={['small', 'alt']} text="custom product"
                                            onClick={() => this.openPopup('customProductTypeSelect', cart, cart.id)} />
                                    <Button modifiers={['small', 'alt']} text="giftcard"
                                            onClick={() => this.openPopup('giftcardCreate', cart, cart.id)}/>
                                    <Dropout title={'Other'} modifiers={'align-right'} buttonModifiers={['alt']}
                                             icon={'list'}>
                                        <ButtonGroup>
                                            { plasticBag }
                                            { ticket }
                                            <Button modifiers={['small']} text="product"
                                                    onClick={() => this.openPopup('productVariationSelect', cart, cart.id)}/>
                                            { cart.customer &&
                                                <Button text="create KB"
                                                        onClick={() => this.openPopup('clientOrderCreate', cart, 'create-client-order')}/>
                                            }
                                            <Button modifiers={'alt'} text="pick KB"
                                                    onClick={() => this.openPopup('clientOrderSelect', cart, 'select-client-order')}/>
                                        </ButtonGroup>
                                    </Dropout>
                                </ButtonGroup>
                            }>
                                <BarcodeScanner
                                    isScanning={!this.state.activeEditingCartItem}
                                    onReleaseFound={(release) => {
                                        this.props.addRelease(cart.id, release.id, {amount: 1}).then(() => {

                                            this.openPopup('cartView', {cart: cart}, cart.id);
                                            this.props.updateList('carts');
                                            this.props.updateList('cart-items-' + cart.id);
                                            ui.notify('Release added to cart');
                                        });
                                    }}
                                    onMultipleReleasesFound={(releases, barcode) => {
                                        this.openPopup('releaseSelect', {
                                            cart: cart,
                                            barcode: barcode,
                                        }, cart.id);
                                    }}
                                    onProductFound={(product) => {
                                        this.props.addProductVariation(cart.id, product.id, {amount: 1}).then(() => {
                                            this.openPopup('cartView', {cart: cart}, cart.id);
                                            this.props.updateList('carts');
                                            this.props.updateList('cart-items-' + cart.id);
                                            ui.notify('Product added to cart');
                                        });
                                    }}
                                    onSecondHand={() => {
                                        if (this.state.secondHandCustomProductType) {
                                            this.openPopup('customProductAdd', {
                                                customProductType: this.state.secondHandCustomProductType,
                                                cart: cart
                                            }, cart.id);
                                        }
                                    }}
                                    onBxlStock={() => {
                                        if (this.state.bxlStockCustomProductType) {
                                            this.openPopup('customProductAdd', {
                                                customProductType: this.state.bxlStockCustomProductType,
                                                cart: cart
                                            }, cart.id);
                                        }
                                    }}
                                    onPlasticBag={() => {
                                        this.props.addCustomProduct(cart.id, this.state.plasticBagCustomProductType.id, {amount: 1}).then(() => {
                                            ui.notify('Plastic bag added to cart');
                                            
                                            this.openPopup('cartView', {cart: cart}, cart.id);
                                            this.props.updateList('carts');
                                            this.props.updateList('cart-items-' + cart.id);
                                        });
                                    }}
                                    onGiftcardFound={(code) => {
                                        this.props.addDiscount(cart.id, code.id)
                                            .then((response) => {
                                                if (response === undefined) {
                                                    ui.notify('Discount code already in cart', 'error');
                                                    return;
                                                }
                                                
                                                ui.notify('Discount added');
                                                
                                                this.openPopup('cartView', {cart: cart}, cart.id);
                                                this.props.updateList('carts');
                                                this.props.updateList('cart-items-' + cart.id);
                                            })
                                            .catch(error => handleTokenErrors(error));
                                    }}
                                />
                                <List
                                    id={'cart-items-' + cart.id}
                                    model={'cart-items'}
                                    selectable={false}
                                    sort={{
                                        sort_created: 'desc',
                                        sort_updated: 'desc',
                                    }}
                                    filters={{
                                        filter_cart: [
                                            {key: cart.id}
                                        ]
                                    }}
                                >
                                    <CartItem
                                        active={this.id === this.state.activeEditingCartItem}
                                        onEdit={cartItem => {
                                            this.setState({
                                                activeEditingCartItem: cartItem.id,
                                            })
                                        }}
                                        onCancel={cartItem => {
                                            this.setState({
                                                activeEditingCartItem: cartItem.id,
                                            })
                                        }}
                                        onDelete={cartItem => {
                                            this.props.removeCartItem(cart.id, cartItem.id).then(() => {
                                                this.props.updateList('carts');
                                                this.props.updateList('cart-items-' + cart.id);
                                                this.openPopup('cartView', {cart: cart}, cart.id);
                                                ui.notify('Item removed from cart');
                                            });
                                        }}
                                        onSave={(cartId, cartItem, data) => {
                                            this.props.updateCartItem(cartId, cartItem.id, data).then(() => {
                                                this.updateView();
                                                this.props.updateList('carts');
                                                this.props.updateList('cart-items-' + cart.id);
                                                this.openPopup('cartView', {cart: cart}, cart.id);
                                                this.updateView('index');
                                                ui.notify('Price updated');
                                            });
                                        }}
                                    />
                                </List>
                            </Window>
                            <Grid rows={'2fr auto 8fr'}>
                                <NumberWidget prefix="€" value={cart.total.toFixed(2)} title="Order total"/>
                                <Window title={'Discount'} modifiers={['frame', 'padded']} header={(cart.discount_code ?
                                    <IconButton modifiers="alt" iconType="delete" onClick={e => {
                                        this.props.removeDiscount(cart.id).then(() => {
                                            ui.notify('Discount removed');
                                            this.props.updateList('carts');
                                            this.openPopup('cartView', {cart: cart}, cart.id);
                                        });
                                    }}/> : null)}>
                                    {discountCode}
                                </Window>
                                <Window title={'Customer'} modifiers={['frame', 'padded']}
                                        header={customerWindowHeader}>
                                    {customerCard}
                                </Window>
                            </Grid>
                        </Grid>
                    </Popup>
                );
            },
            cartCheckoutView: (props) => {
                let content = null;
                let footer = null;
                
                let cart = (this.props.carts[props.cart.id] ? this.props.carts[props.cart.id] : props.cart);
                
                return (
                    <CartCheckoutScreen
                        cart={cart}
                        order={props.order}
                        createInvoice={this.state.createInvoice}
                        onSuccess={(ticket) => {
                            this.setState({
                                createInvoice: false,
                            });
                            
                            if (ticket) {
                                let printWindow = window.open('', '_blank');
                                printWindow.document.open();
                                printWindow.document.write(ticket);
                                printWindow.document.close();
                            }
                            
                            this.props.deleteCart(cart.id).then(() => {
                                this.props.updateList('cash-desk-orders');
                                this.props.updateList('carts');
                                this.props.updateList('open-registers', {
                                    model: 'registers',
                                    filters: {
                                        filter_closed: [
                                            {key: 0}
                                        ]
                                    },
                                    limit: 1
                                })
                                
                                this.closePopup('cartCheckoutView', cart.id);
                                
                                ui.notify('Payment succeeded');
                            });
                            
                            resolve();
                        }}
                        onFail={(message) => {
                            ui.notify(message, 'error');
                            
                            resolve();
                        }}
                        onCancel={() => {
                            http.delete(API_HOST + API_ROOT + 'carts/' + cart.id + '/cancel-order', {}, true)
                                .then(response => {
                                    this.closePopup('cartCheckoutView', cart.id, () => {
                                        this.openPopup('cartView', {cart: cart}, cart.id);
                                    });
                                })
                           
                        }}
                    />
                );
            },
            customerSelect: (cart) => {
                
                cart = (this.props.carts[cart.id] ? this.props.carts[cart.id] : cart);
                
                return (
                    <ItemSelectPopup
                        itemName="Customer"
                        listId={'customers-select'}
                        model={'customers'}
                        onItemSelectConfirm={customer => {
                            
                            this.props.updateCart(cart.id, {
                                customer: customer.id
                            }).then(() => {
                                
                                ui.notify('Customer selected');
                                
                                this.props.updateList('carts');
                                this.openPopup('cartView', {cart: cart}, cart.id);
                                this.closePopup('customerSelect', cart.id);
                            });
                        }}
                        onItemDeselect={() => {

                            this.props.updateCart(cart.id, {
                                customer: null
                            }).then(() => {

                                this.props.updateList('carts');
                                this.openPopup('cartView', {cart: cart}, cart.id, () => {
                                    this.closePopup('customerSelect', cart.id);
                                });
                            });
                        }}
                    >
                        <CustomerSelectListItem/>
                    </ItemSelectPopup>
                );
            },
            releaseSelect: (cart) => {
                let barcode = null;

                if (cart.barcode) {
                    barcode = cart.barcode;
                    cart = (this.props.carts[cart.id] ? this.props.carts[cart.id] : cart.cart);
                }
                else {
                    cart = (this.props.carts[cart.id] ? this.props.carts[cart.id] : cart);
                }

                return (
                    <ItemSelectPopup
                        itemName="Release"
                        listId={'releases-select'}
                        model={'releases'}
                        search={barcode}
                        onItemSelectConfirm={release => {
                            this.props.addRelease(cart.id, release.id, {amount: 1}).then(() => {
                                ui.notify('Release added to cart');

                                this.openPopup('cartView', {cart: cart}, cart.id);
                                this.props.updateList('carts');
                                this.props.updateList('cart-items-' + cart.id);
                            });

                            this.closePopup('releaseSelect', cart.id);
                        }}
                        onItemDeselect={() => this.closePopup('releaseSelect', cart.id)}
                    >
                        <ReleaseCompactItem/>
                    </ItemSelectPopup>
                );
            },
            customerCreate: (cart) => {
                cart = (this.props.carts[cart.id] ? this.props.carts[cart.id] : cart);

                return (
                    <Popup title="Customer">
                        <CustomerCreate onSave={customer => {

                            this.props.updateCart(cart.id, {
                                customer: customer.id
                            }).then(() => {

                                ui.notify('Customer created');
                                this.props.updateList('carts');
                                this.closePopup('customerCreate', cart.id, () => {
                                    this.openPopup('cartView', {cart: cart}, cart.id);
                                });
                            });

                        }}/>
                    </Popup>
                );
            },
            productVariationSelect: (cart) => {
                cart = (this.props.carts[cart.id] ? this.props.carts[cart.id] : cart);

                return (
                    <ItemSelectPopup
                        itemName="Product"
                        listId={'product-variations-select'}
                        model={'product_variations'}
                        onItemSelectConfirm={product => {
                            this.props
                                .addProductVariation(cart.id, product.id, {
                                    amount: 1
                                })
                                .then(() => {
                                    ui.notify('Product added to cart');

                                    this.openPopup('cartView', {
                                        cart: cart
                                    }, cart.id);

                                    this.props.updateList('carts');
                                    this.props.updateList('cart-items-' + cart.id);
                                })

                            this.closePopup('productVariationSelect', cart.id);
                        }}
                        onItemDeselect={() => this.closePopup('productVariationSelect', cart.id)}
                    >
                        <ProductVariationCompactItem/>
                    </ItemSelectPopup>
                );
            },
            customProductTypeSelect: (cart) => {

                cart = (this.props.carts[cart.id] ? this.props.carts[cart.id] : cart);

                return (
                    <ItemSelectPopup
                        itemName="Product type"
                        listId={'custom-product-types-select'}
                        model={'custom-product-types'}
                        fullscreen={false}
                        required={true}
                        onItemSelectConfirm={customProductType => {

                            this.closePopup('customProductTypeSelect', cart.id, () => {
                                this.openPopup('customProductAdd', {
                                    customProductType: customProductType,
                                    cart: cart
                                }, cart.id);
                            });
                        }}
                    >
                        <CustomProductTypeListItem/>
                    </ItemSelectPopup>
                );
            },
            customProductAdd: (props) => {

                let cart = (this.props.carts[props.cart.id] ? this.props.carts[props.cart.id] : props.cart);

                return (
                    <Popup title={'Add ' + props.customProductType.title} footer={<Button text="Create"
                                                                                          onClick={e => this.customProductCreate.current.form.current.submit()}/>}>
                        <CustomProductCreate
                            ref={this.customProductCreate}
                            customProductType={props.customProductType}
                            onSave={customProduct => {

                                this.props.addCustomProduct(cart.id, customProduct.id, {amount: 1}).then(() => {

                                    ui.notify('Custom product added to cart');

                                    this.openPopup('cartView', {cart: cart}, cart.id);
                                    this.props.updateList('carts');
                                    this.props.updateList('cart-items-' + cart.id);
                                });

                                this.closePopup('customProductAdd', cart.id);
                            }}
                            onCancel={() => {
                                this.closePopup('customProductAdd', cart.id, () => {
                                    this.openPopup('customProductTypeSelect', cart, cart.id);
                                });
                            }}
                        />
                    </Popup>
                );
            },
            giftcardCreate: (cart) => {

                cart = (this.props.carts[cart.id] ? this.props.carts[cart.id] : cart);

                return (
                    <Popup title={'Add giftcard'} footer={<Button text="Create"
                                                                  onClick={e => this.giftcardCreate.current.form.current.submit()}/>}>
                        <GiftcardCreate
                            ref={this.giftcardCreate}
                            onSave={giftcard => {

                                this.props.addGiftcard(cart.id, giftcard.id, {amount: 1}).then(() => {

                                    ui.notify('Giftcard added to cart');

                                    this.openPopup('cartView', {cart: cart}, cart.id);
                                    this.props.updateList('carts');
                                    this.props.updateList('cart-items-' + cart.id);
                                });

                                this.closePopup('giftcardCreate', cart.id);
                            }}
                            onCancel={() => {
                                this.closePopup('giftcardCreate', cart.id, () => {
                                    this.openPopup('cartView', {cart: cart}, cart.id);
                                });
                            }}
                        />
                    </Popup>
                );

            },
            clientOrderCreate: (cart) => {

                cart = (this.props.carts[cart.id] ? this.props.carts[cart.id] : cart);

                return (
                    <Popup title={'Create Client Order'}>
                        <ClientOrderCreate onSave={(clientOrder) => {

                            this.props.addClientOrder(cart.id, clientOrder.id, {amount: 1}).then(() => {

                                ui.notify(clientOrder.title + ' created');

                                this.openPopup('cartView', {cart: cart}, cart.id);
                                this.props.updateList('carts');
                                this.props.updateList('cart-items-' + cart.id);
                            });

                            this.closePopup('clientOrderCreate', 'create-client-order');
                        }}/>
                    </Popup>
                );
            },
            clientOrderSelect: (cart) => {

                cart = (this.props.carts[cart.id] ? this.props.carts[cart.id] : cart);

                return (
                    <ItemSelectPopup
                        itemName="Client Orders ready for pickup"
                        listId={'client-orders-select'}
                        model={'client-orders'}
                        filters={{
                            filter_status: [{key: 'ready_for_pickup'}],
                        }}
                        required={true}
                        onItemSelectConfirm={clientOrder => {

                            this.props.addClientOrder(cart.id, clientOrder.id, {amount: 1}).then(() => {

                                ui.notify('Client order added to cart');

                                this.openPopup('cartView', {cart: cart}, cart.id);
                                this.props.updateList('carts');
                                this.props.updateList('cart-items-' + cart.id);
                            });

                            this.closePopup('clientOrderSelect', 'select-client-order');
                        }}
                    >
                        <ClientOrderListItem/>
                    </ItemSelectPopup>
                );
            },
            viewOrder: (order) => {
                return (
                    <Popup title={'Order ' + order.id} toggle={true}>
                        <OrderView order={order}/>
                    </Popup>
                );
            },
            createRegister: () => {
                return (
                    <Popup title={'Register'} toggle={true}>
                        <RegisterCreate onSave={register => {

                            this.updateView();
                            this.closePopup('createRegister', 'create-register', () => {
                                this.updateView('index');
                                ui.notify(register.hid + ' created');
                            })
                        }}/>
                    </Popup>
                );
            },
            manageRegister: (register) => {
                return (
                    <Popup title={'Manage ' + register.hid} toggle={true} fullscreen={true}>
                        <RegisterEdit
                            register={register}
                            onRegisterTransactionCreate={(register, type) => {
                                this.openPopup('createRegisterTransaction', {
                                    register: register,
                                    type: type
                                }, 'create-register-transaction');
                            }}
                            onOpenClosePopup={(register) => {
                                this.openPopup('closeRegister', register, 'close-register');
                            }}
                        />
                    </Popup>
                );
            },
            createRegisterTransaction: (props) => {
                return (
                    <Popup title={props.type === 'in' ? 'Add cash to register' : 'Remove cash from register'}
                           toggle={true}>
                        <RegisterTransactionCreate
                            register={props.register}
                            type={props.type}
                            onSave={register => {
                                this.closePopup('createRegisterTransaction', 'create-register-transaction', () => {
                                    this.closePopup('manageRegister', 'manage-register', () => {
                                        this.openPopup('manageRegister', register, 'manage-register');
                                    });
                                    ui.notify('Register transaction created');
                                });
                            }}
                        />
                    </Popup>
                );
            },
            closeRegister: (register) => {
                return (
                    <Popup title={'Close ' + register.hid} toggle={true}>
                        <RegisterClose register={register} onSave={(register) => {
                            this.props.lists.carts.items.forEach(cart => {
                                this.props.deleteCart(cart.id);
                            });
                            
                            this.updateView();
                            
                            this.closePopup('closeRegister', 'close-register', () => {
                                this.closePopup('manageRegister', 'manage-register', () => {
                                    this.updateView('index');
                                    
                                    ui.notify(register.hid + ' closed');
                                });
                            });
                        }}></RegisterClose>
                    </Popup>
                );
            },
            checkoutOrder: () => {
                return (
                    <Popup title={'Checkout order'} toggle={true}>
                        <BarcodeScanner
                            isScanning={true}
                            list={'orders'}
                            onOrderFound={order => {
                                this.openPopup('checkoutOrderView', order[0]);
                            }}
                            onMultipleOrdersFound={(orders, barcode) => {
                                this.openPopup('checkoutOrderSelect', {
                                    orders: orders,
                                    barcode: barcode,
                                });
                            }}
                        />
                    </Popup>
                );
            },
            checkoutOrderSelect: (checkout) => {
                let barcode = null;
    
                if (checkout.barcode) {
                    barcode = checkout.barcode;
                }
                
                return (
                    <ItemSelectPopup
                        itemName="Order"
                        listId={'orders'}
                        model={'orders'}
                        search={barcode}
                        filters={{
                            filter_status: [{
                                key: 1, value: "Paid",
                            }],
                            filter_fulfillment_method: [{
                                key: 1
                            }],
                            filter_post_payment_status: [{
                                key: 4
                            }]
                        }}
                        onItemSelectConfirm={order => {
                            this.closePopup('checkoutOrder');
                            this.closePopup('checkoutOrderSelect');
                            this.openPopup('checkoutOrderView', order);
                        }}
                        onItemDeselect={() => {
                            this.closePopup('checkoutOrder');
                            this.closePopup('checkoutOrderSelect');
                        }}
                    >
                        <OrderListItem />
                    </ItemSelectPopup>
                );
            },
            checkoutOrderView: (order) => {
                let checkoutButton = order.fulfillment_method === 1 && order.post_payment_status !== 3 && (
                    <Button
                        key={'checkout'}
                        text={'Checkout'}
                        onClick={() => {
                            this.checkoutOrder(order, order.id);
                        }}
                        modifiers={'small'}
                    />
                );
                
                return (
                    <Popup
                        key={order.id}
                        title={'Order ' + order.id}
                        toggle={true}
                        footer={checkoutButton}
                    >
                        <OrderView order={order}/>
                    </Popup>
                );
            }
        };

        this.state = {
            cart: {
                total: null,
                remaining: null,
            },
            view: this.views.index,
            customProductTypes: [],
            createInvoice: false,
            cardPaymentLoading: false,
            payment: {
                total: null,
                remaining: null,
                mollie: null,
                cash: null,
            },
            secondHandCustomProductType: null,
            plasticBagCustomProductType: null,
            bxlStockCustomProductType: null,
            ticketProductType: null,
            activeEditingCartItem: null,
        };
    }

    componentDidMount() {
        http
            .request({url: API_HOST + API_ROOT + 'custom-product-types'}, true)
            .then(response => {
                const { items = [] } = response?.result;
                
                const secondHandCustomProductType = items.find(item => item.title === 'Tweedehands release');
                const ticketProductType = items.find(item => item.title === 'Democrazy ticket');
                const bxlStockCustomProductType = items.find(item => item.title === 'BXL Stock');
                
                this.setState({ secondHandCustomProductType, ticketProductType, bxlStockCustomProductType });
            });
        
        http
            .request({url: API_HOST + API_ROOT + 'custom-products/plastic-bag'}, true)
            .then(response => {
                const plasticBagCustomProductType = response?.result;

                this.setState({ plasticBagCustomProductType });
            })
            .catch(error => {
                if (error === 'plastic_bag_not_found') {
                    ui.notify('Plastic bag not found', 'error');
                }
            });
    }
    
    checkoutOrder(order, orderId) {
        http
            .post(API_HOST + API_ROOT + 'orders/' + orderId + '/checkout', {}, true)
            .then(response => {
                this.closePopup('checkoutOrder');
                this.closePopup('checkoutOrderSelect');
                this.closePopup('checkoutOrderView');
                
                ui.notify('Client order checked out');
            })
            .catch(error => {
                ui.notify('There was an error, client order couldn\'t be checked out', 'error');
            })
    }
}

const mapStateToProps = (state) => ({
    carts: state.carts.carts,
    lists: state.resources.lists,
});

const mapDispatchToProps = {
    setList: setList,
    updateList: updateList,
    getCarts: fetchCarts,
    getCart: fetchCart,
    addDiscount: addDiscount,
    removeDiscount: removeDiscount,
    updateCart: updateCart,
    deleteCart: deleteCart,
    addRelease: addRelease,
    addProductVariation: addProductVariation,
    addCustomProduct: addCustomProduct,
    addGiftcard: addGiftcard,
    addClientOrder: addClientOrder,
    updateRelease: updateRelease,
    updateProductVariation: updateProductVariation,
    removeCartItem: removeCartItem,
    updateCartItem: updateCartItem,
    checkoutCart: checkoutCart,
    checkoutMollieCart: checkoutMollieCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(CartModule);