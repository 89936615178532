import {API_HOST, API_ROOT, http} from "../../../../util/http";

import React from 'react';
import {dateTime, price} from "../../../../util/formatting";
import {orderLabelModifier, paymentMethodLabelModifier} from "../../../../util/label-colors";

import DataValue from "../../data-value/index";
import StatusLabel from "../../../atoms/status-label/index";
import OrderItems from "../order-items";
import OrderGiftcardItems from "../order-giftcard-items";
import Icon from "../../../atoms/icon";

class OrderView extends React.Component {
    static defaultProps = {
        order: {}
    };
    
    constructor(props) {
        super(props);
        this.state = {order: this.props.order};
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.order && (prevProps.order !== this.props.order)) {
            this.fetchOrder();
        }
    }
    
    componentDidMount() {
        this.fetchOrder();
    }
    
    fetchOrder() {
        http.request({
            url: API_HOST + API_ROOT + 'orders/' + this.props.order.id
        }, true).then(response => {
            this.setState({order: response.result});
        });
    }
    
    render() {
        let order = this.state.order;
        let releases;
        let productVariations;
        let customProducts;
        let giftcards;
        let clientOrders;
        let content;
        
        if (order) {
            let type = <Icon type={'storeFront'}/>;
            
            if (order.hr_type === 1) {
                type = <Icon type={'cart'}/>;
            }
            
            content = (
                <div style={{marginBottom: '1rem'}}>
                    <DataValue title="Order id">
                        {order.order_id}
                    </DataValue>
                    <DataValue title="Order type">
                        {type} &nbsp; {order.hr_type} {order.register?.location?.name ? '(' + order.register.location.name + ')' : ''}
                    </DataValue>
                    <DataValue title="Status">
                        <StatusLabel title={order.hr_status} modifiers={orderLabelModifier(order.hr_status)}/>
                    </DataValue>
                    {order.payment_methods.length && (
                        <DataValue title="Payment method">
                            {order.payment_methods.map((method) => (
                                <StatusLabel key={method} title={method} modifiers={paymentMethodLabelModifier(method)} />
                            ))}
                        </DataValue>
                    )}
                    <DataValue title="Fulfillment method">
                        {order.hr_fulfillment_method}
                    </DataValue>
                    <DataValue title="Customer">
                        {order.customer ? (order.customer.first_name + ' ' + order.customer.last_name) : ''}
                    </DataValue>
                    <DataValue title="Date">
                        {dateTime(order.created)}
                    </DataValue>
                    {order.referrer && (
                        <DataValue title="Referral">
                            {order.referrer}
                        </DataValue>
                    )}
                    <DataValue title="Price">
                        {price(order.total_price)}
                    </DataValue>
                    {order.formatted_discount && (
                        <DataValue title="Discount code">
                            {order.formatted_discount}
                        </DataValue>
                    )}
                    {order.checked_out_at && <DataValue title="Checked out at">
                        {dateTime(order.checked_out_at)}
                    </DataValue>}
                </div>
            );
            
            releases = (
                <OrderItems
                    items={this.state.order.order_releases}
                    title={'release.title'}
                    subtitle={'release.subtitle'}
                    thumbnail_url={'release.thumbnail_url'}
                    formats={'release.formats'}
                    is_preordered={'is_preordered'}
                />
            );
            
            productVariations = (
                <OrderItems
                    items={this.state.order.order_product_variations}
                    name={'product_variation.product.name'}
                    subtitle={'product_variation.name'}
                    artist={'product_variation.product.artist.name'}
                    thumbnail_url={'product_variation.product.thumbnail_url'}
                    is_preordered={'is_preordered'}
                />
            );
            
            customProducts = <OrderItems items={this.state.order.custom_products}/>;
            giftcards = <OrderGiftcardItems items={this.state.order.giftcards}/>;
            
            clientOrders = (
                <OrderItems
                    items={this.state.order.order_client_orders}
                    title={'client_order.title'}
                    subtitle={'client_order.subtitle'}
                    thumbnail_url={'client_order.thumbnail_url'}
                />
            );
        }
        
        return (
            <React.Fragment>
                {content}
                {releases}
                {productVariations}
                {customProducts}
                {giftcards}
                {clientOrders}
            </React.Fragment>
        );
    }
}

export default OrderView;