import {connect} from "react-redux";
import Grid from "../../grid";
import Popup from "../../popup";
import React from "react";
import Loader from "../../../atoms/loader";
import Button from "../../../atoms/button";
import TextInput from "../../../atoms/text-input";
import ButtonGroup from "../../button-group";
import ActionButton from "../../../atoms/action-button";
import NumberWidget from "../../number-widget";
import {handleTokenErrors} from "../../../../state/modules/errors";
import {checkoutCart, checkoutMollieCart, payOrder} from "../../../../state/modules/carts";
import {API_HOST, API_ROOT, http} from "../../../../util/http";

class CartCheckoutScreen extends React.Component {
    static defaultProps = {
        cart: null,
        createInvoice: false,
        onCancel: () => {
        },
        onSuccess: (method) => {
        },
        onFail: (message) => {
        },
    };
    
    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            method: null,
            active: null,
            collected: {
                total: 0,
                discount: 0,
                cash: 0,
                mollie: 0,
            },
            received: {
                total: 0,
                cash: 0,
                mollie: 0,
            },
            remaining: this.props.cart.total,
        }
    }
    
    chooseCash() {
        if (this.props.cart.total === 0) {
            this.payCash();
            return;
        }
        
        document.addEventListener("keyup", this.handlePay.bind(this));
        
        this.setState({
            method: 'cash',
            remaining: this.props.cart.total - this.state.collected?.cash,
        });
    }
    
    handlePay(e) {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            
            this.payCash();
        }
    }
    
    payCash() {
        document.removeEventListener("keyup", this.handlePay.bind(this));
        
        this.props
            .payOrder(this.props.cart.id, 'cash', this.state.createInvoice, this.state.received.cash)
            .then((response) => {
                if (!response.success) {
                    return;
                }
                
                if (!response.result.orderFullyPaid) {
                    this.setState({
                        collected: {
                            total: response.result.collected?.total,
                            cash: response.result.collected?.cash,
                            mollie: response.result.collected?.mollie,
                        },
                        received: {
                            cash: 0,
                            mollie: 0,
                        },
                        remaining: response.result.remaining,
                    });
                    
                    this.toggleLoading();
                    
                    return;
                }
                
                if (response.result.ticket) {
                    this.props.onSuccess(response.result.ticket);
                }
                
                this.props.onSuccess();
            });
    }
    
    payCard() {
        this.props
            .payOrder(this.props.cart.id, 'card', this.state.createInvoice)
            .then((response) => {
                this.props.onSuccess();
            });
    }
    
    async payMollie() {
        this.toggleLoading();
        
        this.setState({
            method: 'mollie',
        });
        
        await this.props.payOrder(this.props.cart.id, 'mollie', this.state.createInvoice, this.state.remaining)
            .then((response) => {
                if (!response.success) {
                    return;
                }
                
                const paymentCheck = setInterval(() => {
                    http
                        .request({url: API_HOST + API_ROOT + 'carts/' + this.props.cart.id + '/checkout-status'}, true)
                        .then(response => {
                            if (response.result.status === 0) {
                                return;
                            }
                            
                            if (response.result.status === 1) {
                                clearInterval(paymentCheck);
                                
                                const sound = new Audio(process.env.PUBLIC_URL + 'mp3/kaching.mp3');
                                sound.play();
                                
                                this.props.onSuccess(response.result.ticket);
                                
                                return;
                            }
                            
                            if (response.result.status === 2) {
                                this.props.onFail('Payment cancelled');
                            }
                            
                            if (response.result.status === 3) {
                                this.props.onFail('Payment failed');
                            }
                            
                            if (response.result.status === 4) {
                                this.props.onFail('Payment expired');
                            }
                            
                            clearInterval(paymentCheck);
                            
                            this.toggleLoading();
                        });
                }, 1000);
            })
            .catch(error => {
                handleTokenErrors(error);
                
                if (error.message === 'payment_failed') {
                    this.props.onFail('Payment failed');
                }
                
                this.toggleLoading();
            })
    }
    
    toggleLoading() {
        this.setState({
            method: null,
        });
    }
    
    handleCancel() {
        if (!this.state.method) {
            this.props.onCancel();
        }
        
        this.toggleLoading();
    }
    
    handleCancelOrder() {
        if (!this.state.method) {
            this.props.onCancel();
        }
        
        this.toggleLoading();
    }
    
    render() {
        let footer = (
            <ButtonGroup>
                {!this.state.method && (
                    <Button text={'Cancel order'} modifiers={'alt'} onClick={this.handleCancelOrder.bind(this)}/>
                )}
                {this.state.method && this.state.method !== 'mollie' && (
                    <Button text={'Cancel'} modifiers={'alt'} onClick={this.handleCancel.bind(this)}/>
                )}
                {this.state.method === 'cash' && (
                    <Button text={'Pay'} onClick={event => {
                        this.payCash();
                    }}/>
                )}
            </ButtonGroup>
        );
        
        const receivedCash = this.state.received.cash;
        
        return (
            <Popup title={'Cart #' + this.props.cart.id} footer={footer} onClose={this.handleCancel.bind(this)}>
                <Grid>
                    <Grid modifiers={'padded'}>
                        <NumberWidget
                            prefix={'€'}
                            value={this.props.cart.total.toFixed(2)}
                            title={'Order total'}
                        />
                        <NumberWidget
                            prefix={'€'}
                            value={(this.state.collected.total).toFixed(2) }
                            title={'Received'}
                            modifiers={'success'}
                        />
                        <NumberWidget
                            prefix={'€'}
                            value={this.state.remaining?.toFixed(2)}
                            title={this.state.remaining > 0 ? 'Remaining' : 'Change'}
                            modifiers={this.state.remaining > 0 ? 'warning' : 'success'}
                        />
                    </Grid>
                </Grid>
                
                {!this.state.method && (
                    <Grid cols={'1fr 1fr'}>
                        <ActionButton modifiers={'xl'} text={'Pay Mollie'} onClick={this.payMollie.bind(this)}/>
                        {/*<ActionButton modifiers={'xl'} text={'Pay card'} onClick={this.payCard.bind(this)}/>*/}
                        <ActionButton modifiers={'xl'} text={'Pay cash'} onClick={this.chooseCash.bind(this)}/>
                    </Grid>
                )}
                
                {this.state.method === 'cash' && (
                    <div className="content-loader">
                        <TextInput
                            modifiers={'big'}
                            name="received"
                            label="Received"
                            type="number"
                            showRequiredIndicator={true}
                            focus={true}
                            onChange={(value) => {
                                value = parseFloat(value.toString().replace(',', '.'));
                                
                                if (isNaN(value)) {
                                    value = 0
                                }
                                
                                this.setState({
                                    received: {
                                        cash: value
                                    },
                                    remaining: this.props.cart.total - this.state.collected?.cash - value,
                                });
                            }}
                        />
                    </div>
                )}
                
                {this.state.method === 'mollie' && (
                    <div className="content-loader">
                        <Loader isLoading={true} size="large"/>
                    </div>
                )}
            </Popup>
        );
    }
}

const mapStateToProps = (state) => ({
    carts: state.carts.carts,
    lists: state.resources.lists,
});

const mapDispatchToProps = {
    payOrder: payOrder,
    checkoutCart: checkoutCart,
    checkoutMollieCart: checkoutMollieCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(CartCheckoutScreen);