import {API_HOST, API_ROOT, http} from "../../util/http";
import {handleTokenErrors} from "./errors";
import ui from "../../util/ui";

/*
  Constants
 */
export const SET_CARTS = "carts/SET_CARTS";
export const SET_CART = "carts/SET_CART";
export const UNSET_CART = "carts/UNSET_CART";

/*
  Actions Creators
 */
export function setCarts(carts) {
    return {
        type: SET_CARTS,
        payload: {
            carts: carts
        }
    }
}

export function setCart(cart = null) {
    return {
        type: SET_CART,
        payload: {
            cart: cart
        }
    }
}

export function unsetCart(identifier) {
    return {
        type: UNSET_CART,
        payload: {
            identifier: identifier
        }
    }
}

export function fetchCarts() {
    
    return (dispatch) => {
        
        return http.request({url: API_HOST + API_ROOT + 'carts'}, true)
            .then(response => {
                
                dispatch(setCarts(response.result.items));
                
                if (response.result.items.length) {
                    dispatch(setCart(response.result.items[0]));
                    
                } else {
                    dispatch(addCart());
                }
            })
            .catch(error => handleTokenErrors(error));
    };
}

export function fetchCart(identifier) {
    
    return (dispatch) => {
        
        return http.request({url: API_HOST + API_ROOT + 'carts/' + identifier}, true)
            .then(response => dispatch(setCart(response.result)))
            .catch(error => handleTokenErrors(error));
    };
}

export function addCart(register) {
    return (dispatch) => {
        return http.post(API_HOST + API_ROOT + 'carts', {register: register.id}, true);
    };
}

export function updateCart(identifier, data) {
    return (dispatch) => {
        return http.patch(API_HOST + API_ROOT + 'carts/' + identifier, data, true)
            .then(response => dispatch(setCart(response.result)))
            .catch(error => handleTokenErrors(error));
    }
}

export function deleteCart(cartId) {
    return (dispatch) => {
        return http.delete(API_HOST + API_ROOT + 'carts/' + cartId, true)
            .then(response => {
                dispatch(unsetCart(cartId));
                dispatch(setCart());
            })
            .catch(error => handleTokenErrors(error));
    };
}

export function addDiscount(cartId, discountCode) {
    return (dispatch) => {
        return http.put(API_HOST + API_ROOT + 'carts/' + cartId + '/discounts/' + discountCode, null, true)
            .then(response => dispatch(setCart(response.result)))
            .catch(error => handleTokenErrors(error));
    };
}

export function removeDiscount(cartId, discountCode) {
    return (dispatch) => {
        return http.delete(API_HOST + API_ROOT + 'carts/' + cartId + '/discounts/' + discountCode, true)
            .then(response => dispatch(setCart(response.result)))
            .catch(error => handleTokenErrors(error));
    };
}

export function addRelease(cartId, releaseId, data) {
    return (dispatch) => {
        return http.put(API_HOST + API_ROOT + 'carts/' + cartId + '/releases/' + releaseId, data, true)
            .then(response => dispatch(setCart(response.result)))
            .catch(error => handleTokenErrors(error));
    };
}

export function addProductVariation(cartId, productId, data) {
    return (dispatch) => {
        return http.put(API_HOST + API_ROOT + 'carts/' + cartId + '/product_variations/' + productId, data, true)
            .then(response => dispatch(setCart(response.result)))
            .catch(error => handleTokenErrors(error));
    };
}

export function addCustomProduct(cartId, customProductId, data) {
    return (dispatch) => {
        return http.put(API_HOST + API_ROOT + 'carts/' + cartId + '/custom-products/' + customProductId, data, true)
            .then(response => dispatch(setCart(response.result)))
            .catch(error => handleTokenErrors(error));
    };
}

export function addGiftcard(cartId, giftcardId, data) {
    return (dispatch) => {
        return http.put(API_HOST + API_ROOT + 'carts/' + cartId + '/giftcards/' + giftcardId, data, true)
            .then(response => dispatch(setCart(response.result)))
            .catch(error => handleTokenErrors(error));
    };
}

export function addClientOrder(cartId, clientOrderId, data) {
    return (dispatch) => {
        return http.put(API_HOST + API_ROOT + 'carts/' + cartId + '/client-orders/' + clientOrderId, data, true)
            .then(response => dispatch(setCart(response.result)))
            .catch(error => handleTokenErrors(error));
    };
}

export function updateRelease(identifier, releaseId, data) {
    
    return (dispatch) => {
        
        return http.patch(API_HOST + API_ROOT + 'carts/' + identifier + '/releases/' + releaseId, data, true)
            .then(response => dispatch(setCart(response.result)))
            .catch(error => handleTokenErrors(error));
    };
}

export function updateProductVariation(identifier, productId, data) {
    return (dispatch) => {
        
        return http.patch(API_HOST + API_ROOT + 'carts/' + identifier + '/product_variations/' + productId, data, true)
            .then(response => dispatch(setCart(response.result)))
            .catch(error => handleTokenErrors(error));
    };
}

export function updateCartItem(cartId, cartItemId, data) {
    return (dispatch) => {
        
        return http.post(API_HOST + API_ROOT + 'carts/' + cartId + '/cart-items/' + cartItemId + '/update-price', data, true)
            .then(response => dispatch(setCart(response.result)))
            .catch(error => handleTokenErrors(error));
    };
}

export function removeCartItem(cartId, cartItemId) {
    
    return (dispatch) => {
        
        return http.delete(API_HOST + API_ROOT + 'carts/' + cartId + '/cart-items/' + cartItemId, true)
            .then(response => dispatch(setCart(response.result)))
            .catch(error => handleTokenErrors(error));
    };
}

export function payOrder(cartId, paymentMethod, createInvoice, amount) {
    return (dispatch) => {
        return http.post(API_HOST + API_ROOT + 'carts/' + cartId + '/pay-order', {
                amount: amount,
                payment_method: paymentMethod,
                create_invoice: createInvoice,
            }, true)
            // .then(response => dispatch(deleteCart(cartId)))
            .catch(error => handleTokenErrors(error));
    };
}

export function checkoutCart(cartId, paymentMethod, createInvoice, register) {
    return (dispatch) => {
        
        return http.get(API_HOST + API_ROOT + 'carts/' + cartId + '/checkout', true, {
                payment_method: paymentMethod,
                create_invoice: createInvoice
            })
            .then(response => dispatch(deleteCart(cartId)))
            .catch(error => handleTokenErrors(error));
    };
}

export function checkoutMollieCart(cartId, paymentMethod, createInvoice, paidWithCash) {
    
    return (dispatch) => {
        
        return http.get(API_HOST + API_ROOT + 'carts/' + cartId + '/checkout', true, {
                payment_method: paymentMethod,
                create_invoice: createInvoice,
                paid_with_cash: paidWithCash,
            })
            .then(response => {
                if (paymentMethod !== 'mollie') {
                    dispatch(deleteCart(cartId))
                }
            });
    };
}

/*
  Initial State
 */
const initialState = {
    carts: {}
};

/*
  Reducers
 */
export default function reducer(state = initialState, action) {
    
    switch (action.type) {
        
        case SET_CARTS: {
            return {
                ...state,
                carts: action.payload.carts
            }
        }
        
        case SET_CART: {
            return {
                ...state,
                cart: action.payload.cart || state.carts[state.carts.length - 1],
                carts: action.payload.cart ? updateCarts(action.payload.cart, state.carts) : state.carts
            }
        }
        
        case UNSET_CART: {
            return {
                ...state,
                carts: removeCartFromCarts(action.payload.identifier, state.carts)
            }
        }
        
        default:
            return state;
    }
};

function updateCarts(cart, carts) {
    
    let newCarts = {...carts};
    newCarts[cart.id] = cart;
    return newCarts;
}

function removeCartFromCarts(id, carts) {
    return carts.filter(c => c.id !== id);
}