import React from 'react';
import { photoSrc } from "../../../../util/media";
import {date, dateTime, price} from "../../../../util/formatting";

import IconLabel from "../../../atoms/icon-label";
import Label from "../../../atoms/label";
import IconButton from "../../../atoms/icon-button";
import StatusLabel from "../../../atoms/status-label";
import Dropout from "../../dropout";

class PickListRow extends React.Component {
    static defaultProps = {
        model: {},
        onViewList: () => {}
    };
    
    render() {
        return (
            <div className="list-item">
                <div className="list-item__column">
                    {this.props.model.id}
                </div>
                <div className="list-item__column list-item__column--span-4">
                    {this.props.model.location.name}
                </div>
                <div className="list-item__column">
                    {dateTime(this.props.model.created)}
                </div>
                <div className="list-item__column">
                    {this.props.model.status && (
                        <StatusLabel title={this.props.model.status} modifiers={this.props.model.status === 'pending' ? 'warning' : 'success'} />
                    )}
                </div>
                <div className="list-item__column">
                    <IconButton iconType={'eye'} onClick={() => this.props.onViewList(this.props.model)}/>
                </div>
            </div>
        );
    }
}

export default PickListRow;